.EmailIcon{
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 50%;
}

.EmailIcon-Base{
    position: absolute;
    top: 20%;
    left: 15%;
    width: 70%;
    height: 60%;
    border-radius: 6px;
    background-color: rgba(34,34,34,0.1);
    box-shadow: 1px 1px 3px 1px rgba(34,34,34,0.5);
}

.EmailIcon-Top{
    position: absolute;
    top: 20%;
    left: 15%;
    width: 70%;
    height: 30%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 40px 20px;
    border-bottom-right-radius: 40px 20px;
    box-shadow: 1px 1px 1px 1px rgba(34,34,34,0.3);
    transition: all 0.3s linear;
}

.EmailIcon-Seal{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0%;
    height: 0%;
    border-radius: 50%;
    /* background-color:white; */
    transition: all 0.3s linear;
}

.EmailIcon-Seal-Trial{
    top: 38%;
    left: 35%;
    width: 30%;
    height: 30%;
    box-shadow: 1px 1px 3px 1px rgba(34,34,34,0.5), 0px 0px 1px 1px rgba(34,34,34,0.2);
}

.EmailIcon-Top-Trial{
    border-bottom-left-radius: 50px 30px;
    border-bottom-right-radius: 50px 30px;
    height: 50%;    
}