.ContactItem{
    width:370px;
    min-height:200px;
    background-color: var(--bcg-color-three);
    border-radius: 15px;
    box-shadow: 1px 1px 5px 3px rgba(34,34,34,0.2);
    padding-bottom:10px;
    margin: auto;
}

.ContactItem-Title{
    padding-top:20px;
    font-size: 100%;
    font-weight: bold;
    color: var(--bcg-color-four);
}

.ContactItem-Name{
    font-size: 90%;
    font-weight: bold;
    color: var(--bcg-color-four);
    text-align: center;    
}

.ContactItem-Text{
    text-align: left;
    padding-top:20px;
    font-size: 80%;
    color: var(--bcg-color-four);
}

.ContactItem-Details-IconHolder{
    box-shadow: 1px 1px 3px 1px rgba(34,34,34,0.6), 0px 0px 1px 1px rgba(34,34,34,0.2);
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin-top: 5px;
}

.ContactItem-DetailsContainer{
    padding-left:20px;
    display: flex;
    align-items: center;
    margin-bottom:20px;
}

.ContactItem-Details{
    padding-left:20px;
}