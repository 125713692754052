:root{
    --aboutussign-bgc-color:rgba(240,240,240,1);
}

.AboutUsSign{
    width:90%;
    margin: auto;
    z-index: 2;
    margin-top:50px;
    margin-bottom:50px;
}

.AboutUsSign-ImageHolder{
    border-radius: 50%;
    height: 100%;
    width: 100%;
    margin: auto;
    background-color: blueviolet;
    z-index: 3;
    position: relative;
}

.AboutUsSign-ImageContainer{
    position: absolute;
    border-radius: 50%;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    box-shadow: 0px -1px 5px 3px rgba(34,34,34,0.4);
    background-color: white;
    z-index: 3;
}

.AboutUsSign-ImageItem{
    position: absolute;
    border-radius: 50%;
    top:5%;
    left:5%;
    height: 90%;
    width: 90%;
    display: flex;
    background-color:var(--aboutussign-bgc-color);
    z-index: 4;
}

.AboutUsSign-ImageItem-VisualElement-Body{
    position: absolute;
    border-top-left-radius: 30px 60px;
    border-top-right-radius: 30px 60px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    top: 42%;
    left: 30%;
    height: 40%;
    width: 40%;
    box-shadow: 0px -5px 13px 2px rgba(34,34,34,0.3),0px 0px 6px 3px rgba(255,255,255,1) inset;
    background-color:var(--aboutussign-bgc-color);
    z-index: 4;
    transition: all 0.3s linear;
}

.AboutUsSign-ImageItem-VisualElement-Head{
    position: absolute;
    border-radius: 50%;
    top: 18%;
    left: 40%;
    height: 20%;
    width: 20%;
    box-shadow: 0px -5px 13px 2px rgba(34,34,34,0.3),0px 0px 6px 3px rgba(255,255,255,1) inset;
    z-index: 4;
    transition: all 0.3s linear;
}


.AboutUsSign-ImageItem-VisualElement-Body-Small{
    position: absolute;
    border-top-left-radius: 30px 60px;
    border-top-right-radius: 30px 60px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    top: 42%;
    left: 11%;
    height: 30%;
    width: 30%;
    box-shadow: 0px -5px 13px 2px rgba(34,34,34,0.3),0px 0px 6px 3px rgba(255,255,255,1) inset;
    z-index: 4;
    transition: all 0.3s linear;
}

.AboutUsSign-ImageItem-VisualElement-Head-Small{
    position: absolute;
    border-radius: 50%;
    top: 24%;
    left: 19%;
    height: 15%;
    width: 15%;
    box-shadow: 0px -5px 13px 2px rgba(34,34,34,0.3),0px 0px 6px 3px rgba(255,255,255,1) inset;
    z-index: 4;
    transition: all 0.3s linear;
}

.AboutUsSign-ImageItem-VisualElement-Body-Small-Right{
    position: absolute;
    border-top-left-radius: 30px 60px;
    border-top-right-radius: 30px 60px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    top: 42%;
    left: 60%;
    height: 30%;
    width: 30%;
    box-shadow: 0px -5px 13px 2px rgba(34,34,34,0.3),0px 0px 6px 3px rgba(255,255,255,1) inset;
    z-index: 4;
    transition: all 0.3s linear;
}

.AboutUsSign-ImageItem-VisualElement-Head-Small-Right{
    position: absolute;
    border-radius: 50%;
    top: 24%;
    left: 68%;
    height: 15%;
    width: 15%;
    box-shadow: 0px -5px 13px 2px rgba(34,34,34,0.3),0px 0px 6px 3px rgba(255,255,255,1) inset;
    z-index: 4;
    transition: all 0.3s linear;
}

.AboutUsSign-ImageItem-VisualElement-SpinOne{
    transform: rotate(120deg);
}

.AboutUsSign-ImageItem-VisualElement-SpinTwo{
    transform: rotate(240deg);
}

.AboutUsSign-Image{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    z-index: 5;
}

.AboutUsSign-ImageItem-SmallFigureLeft{
    position: absolute;
    width:100%;
    height:100%;
}

.AboutUsSign-ImageItem-SmallFigureRight{
    position: absolute;
    width:100%;
    height:100%;
}

.AboutUsSign-ImageItem-BigFigure{
    position: absolute;
    width:100%;
    height:100%;
}

.AboutUsSign-MoveLeftAnimation{
    animation:1.5s linear 1 both running slideLeft;
}

@keyframes slideLeft {
    0% {
        transform: rotate(0deg);    
    }
    20% {
        transform: translateX(-10px) scale(1.05,1.05);        
    }  
    100% {
        transform: rotate(0deg); 
    }
  }

.AboutUsSign-MoveRightAnimation{
    animation:1.5s linear 1 both running slideRight;
}

@keyframes slideRight {
    0% {
        transform: rotate(0deg);    
    }
    20% {
        transform: translateX(10px) scale(1.05,1.05);        
    }
    100% {
        transform: rotate(0deg); 
    }
  }

.AboutUsSign-ScaleFigureAnimation{
    animation:1.5s linear 1 both running scaleMidFigure;
}

@keyframes scaleMidFigure {
    0% {
        transform: scale(1,1);    
    }
    20% {
        transform: scale(1.1,1.1);        
    }
    100% {
        transform: scale(1,1); 
    }
  }
