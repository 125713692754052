.OurResults{
    width:90%;
    margin: auto;
    z-index: 2;
    transition: all 0.25s linear;
}

.OurResults-ImageHolder{
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 150px;
    width: 200px;
    margin: auto;
    background-color: white;
    z-index: 3;
    box-shadow: 1px 1px 3px 5px rgba(34,34,34,0.2), 0px -6px 20px 0px rgba(34,34,34,0.2);
    position: relative;
    transition: all 0.3s linear;
}

.OurResults-ImageHolderAnimation{
    animation:1.5s linear 1 both running clipAnimation;
}

@keyframes clipAnimation {
    0% {

    }
    20% {
        box-shadow: 1px 16px 5px 0px rgba(34,34,34,0.3), 0px -6px 25px 0px rgba(34,34,34,0.2), 0px -20px 17px 2px rgba(34,34,34,0.2) inset;        
        transform:scale(1.0,1.05);
    }

    70% {
        box-shadow: 1px 16px 5px 0px rgba(34,34,34,0.3), 0px -6px 25px 0px rgba(34,34,34,0.2), 0px -20px 17px 2px rgba(34,34,34,0.2) inset;        
        transform:scale(1,1);
    }
  
    100% {

    }
  }



.OurResults-ImageContainer{
    position: absolute;
    border-radius: 50%;
    top:20px;
    left:60px;
    height: 80px;
    width: 80px;
    box-shadow: 0px -1px 5px 3px rgba(34,34,34,0.2);
    background-color: rgb(234,234,234);
    z-index: 3;
    transition: all o.25s linear;
}

.OurResults-ImageItem{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;    
    background-color: rgba(255,253,253,1);
    box-shadow: 1px 1px 5px 3px rgba(34,34,34,0.2);
    z-index: 4;
}

.OurResults-Image{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    z-index: 5;
}

.OurResults-TextContainer{
    font-size:100%;
    margin-top: -100px;
    background-image: linear-gradient(216deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 25%, rgba(42, 42, 42, 0.05) 25%, rgba(42, 42, 42, 0.05) 38%, rgba(223, 223, 223, 0.05) 38%, rgba(223, 223, 223, 0.05) 75%, rgba(36, 36, 36, 0.05) 75%, rgba(36, 36, 36, 0.05) 100%), linear-gradient(44deg, rgba(128, 128, 128, 0.05) 0%, rgba(128, 128, 128, 0.05) 34%, rgba(212, 212, 212, 0.05) 34%, rgba(212, 212, 212, 0.05) 57%, rgba(25, 25, 25, 0.05) 57%, rgba(25, 25, 25, 0.05) 89%, rgba(135, 135, 135, 0.05) 89%, rgba(135, 135, 135, 0.05) 100%), linear-gradient(241deg, rgba(55, 55, 55, 0.05) 0%, rgba(55, 55, 55, 0.05) 14%, rgba(209, 209, 209, 0.05) 14%, rgba(209, 209, 209, 0.05) 60%, rgba(245, 245, 245, 0.05) 60%, rgba(245, 245, 245, 0.05) 69%, rgba(164, 164, 164, 0.05) 69%, rgba(164, 164, 164, 0.05) 100%), linear-gradient(249deg, rgba(248, 248, 248, 0.05) 0%, rgba(248, 248, 248, 0.05) 32%, rgba(148, 148, 148, 0.05) 32%, rgba(148, 148, 148, 0.05) 35%, rgba(202, 202, 202, 0.05) 35%, rgba(202, 202, 202, 0.05) 51%, rgba(181, 181, 181, 0.05) 51%, rgba(181, 181, 181, 0.05) 100%), 
    linear-gradient(92deg, rgba(127,126,255,1), rgb(167, 167, 253));
    height:100%;
    border-radius: 20px;
    box-shadow: 0px 10px 7px 3px rgba(34,34,34,0.4);
    transition: all 0.25s linear;
}

.OurResults-Title{
    margin-top: 90px;
    font-size:200%;
    font-weight: bold;
    color:var(--bcg-color-four);
}

.OurResults-Text{
    font-size:130%;
    color:var(--bcg-color-four);
    text-align: center;
    width: 80%;
    margin: auto;
    margin-bottom: 30px;
    margin-top:10px;
}

@media(max-width:900px){
    .OurResults-ImageHolder{
        height: 112px;
        width: 150px;
    }

    .OurResults-ImageContainer{
        top:20px;
        left:50px;
        height: 50px;
        width: 50px;
    }

    .OurResults-TextContainer{
        margin-top:-65px;
    }
}

@media(max-width:900px){
    /* .OurResults{
        width:95%;
    } */
    .OurResults-TextContainer{
        border-radius: 40px;
        padding-bottom: 30px;
    }
    
}

@media(max-width:600px){
    .OurResults{
        width:95%;
    }
    .OurResults-TextContainer{
        border-radius: 0px;
    }
    
}