.SummaryView{
    width:90%;
    margin: auto;
    z-index: 2;
    margin-top:10px;
    margin-bottom:50px;
    transition: all 0.25s linear;
}

.SummaryView-ImageHolder{
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin: auto;
    background-color: blueviolet;
    z-index: 3;
    position: relative;
}

.SummaryView-ImageContainer{
    position: absolute;
    border-radius: 50%;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    box-shadow: 0px -1px 5px 3px rgba(34,34,34,0.2);
    background-color: white;
    z-index: 3;
}

.SummaryView-ImageItem{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    background-color: rgba(35,12,51,1);
    z-index: 4;
}

.SummaryView-Image{
    position: absolute;
    border-radius: 50%;
    filter: contrast(0%) brightness(2);
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    z-index: 5;
}

.SummaryView-TextContainer{
    font-size:100%;
    margin-top: -250px;
    min-height: 60vh;
    background: linear-gradient(rgba(35,12,51,1), rgb(49, 17, 71));
    height:100%;
    border-radius: 20px;
    box-shadow: 0px 10px 7px 3px rgba(34,34,34,0.4);
    transition: all 0.25s linear;
}

.SummaryView-Title{
    margin-top: 90px;
    padding-top: 160px;
    font-size:200%;
    font-weight: bold;
    color:white;
}

.SummaryView-Text{
    font-size:100%;
    color:white;
    text-align: left;
    padding-top: 10px;
    /* width: 70%; */
    width: 85%;
    margin: auto;
    padding-bottom: 20px;
}

@media(max-width:900px){
    .SummaryView-TextContainer{
        border-radius: 40px;
    }
}

@media(max-width:600px){
    .SummaryView{
        width:95%;
    }

    .SummaryView-TextContainer{
        border-radius: 0px;
    }
}