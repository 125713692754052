/* :root{

    --contactussign-bgc-color:rgba(240,240,240,1);
} */

.ContactUsSign{
    position: relative;
    width:100%;
    height:100%;
}

.ContactUsSign-LogoImage{
    position: absolute;
    top:10%;
    left:10%;
    width: 80%;
    height: 80%;
    filter: contrast(0%) brightness(2);
    transition: all 0.3s linear;
}

.ContactUsSign-LogoImage-Altered{
    top: 30%;
    left: 30%;
    width: 40%;
    height: 40%;
}

.ContactUsSign-EnvelopeBase{
    position: absolute;
    top: 0%;
    left: -10%;
    width: 120%;
    height: 100%;
    transform:scale(0,0);  
    box-shadow: 1px 1px 7px 5px rgba(34,34,34,0.2);
    border-radius: 5px;
    background-color: white;
    transition: all 0.3s linear;
}

.ContactUsSign-EnvelopeTip{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 60%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 45px 20px;
    border-bottom-left-radius: 45px 20px;
    box-shadow: 0px 3px 11px 5px rgba(34,34,34,0.2),0px 1px 5px 3px rgba(34,34,34,0.2);
    transition: all 0.3s linear;
}

.ContactUsSign-EnvelopeSeal{
    position: absolute;
    top: calc(55% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background-color: var(--bcg-color-four);
    box-shadow: 0px 3px 11px 5px rgba(34,34,34,0.3);
    border-radius: 50%;
}

.ContactUsSign-EnvelopeSealImage{
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    filter: contrast(0%) brightness(2);
}

.ContactUsSign-EnvelopeBase-MoveAnimation{
    animation:3s linear 1 both running envelopeMoveAnimation;
}

@keyframes envelopeMoveAnimation {
    0% {
        transform:scale(0.5,0.5);    
    }

    15% {
        transform:rotate(10deg);      
    }
    
    30%{
        transform:rotate(-10deg);
    }

    45%{
        transform:rotate(0deg);
    }

    75%{
        transform:scale(1,1);    
    }

    95%{
        transform:scale(1,1);
    }

    100% {
        transform:scale(0.58,0.58);    
    }

  }

.ContactUsSign-PhoneHolder{
    position: absolute;
    top:-35%;
    left:-25%;
    width: 150%;
    height: 50%;
    width: 0%;
    height: 0%;
    transition: all 0.3s linear;
}

.ContactUsSign-PhoneHolder-MoveAnimation{
    animation:3s linear 1 both running phoneMoveAnimation;
}

@keyframes phoneMoveAnimation {
    0% {
    }

    5% {
        width: 150%;
        height: 50%;
    }

    25% {
        transform:rotate(10deg);      
    }
    
    75%{
        transform:rotate(-10deg);
    }

    90%{
        width: 150%;
        height: 50%;        
    }
    100% {
        width: 150%;
        height: 0%;
    }
  }

.ContactUsSign-PhoneBase{
    position: absolute;
    border-top-left-radius: 50px 40px;
    border-top-right-radius: 50px 40px;
    top:0%;
    left:0%;
    width: 100%;
    height: 50%;
    background-color: var(--bcg-color-four);
}

.ContactUsSign-PhoneElementOne{
    position: absolute;
    border-bottom-left-radius: 50px 40px;
    border-bottom-right-radius: 50px 40px;
    top:50%;
    left:0%;
    width: 30%;
    height: 50%;
    background-color: var(--bcg-color-four);
}

.ContactUsSign-PhoneElementTwo{
    position: absolute;
    border-bottom-left-radius: 50px 40px;
    border-bottom-right-radius: 50px 40px;
    top:50%;
    left:70%;
    width: 30%;
    height: 50%;
    background-color: var(--bcg-color-four);
}

.ContactUsSign-DotHolder{
    position: absolute;
    border-radius: 50%;
    top:0%;
    left:0%;
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
    opacity: 0;
}

.ContactUsSign-DotHolder-TurnAnimation{
    animation:3s linear 1 both 1s dialTurnAnimation;
}

@keyframes dialTurnAnimation {
    0% {
        opacity: 0;
    }
    3%{
        opacity: 1;
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(80deg);      
    }
    
    90%{
        transform: rotate(0deg);
        opacity: 1;
    }
    100% {
    }
  }

.ContactUsSign-DotOne{
    background-color: var(--bcg-color-three);
    position: absolute;
    box-shadow: 0px 0px 3px 3px var(--bcg-color-four);
    border-radius: 50%;
    top:70%;
    left:40%;
    width: 20%;
    height: 20%;
}

.ContactUsSign-DotTwo{
    background-color: var(--bcg-color-three);
    position: absolute;
    box-shadow: 0px 0px 3px 3px var(--bcg-color-four);
    border-radius: 50%;
    top:62%;
    left:16%;
    width: 15%;
    height: 15%;
}

.ContactUsSign-DotThree{
    background-color: var(--bcg-color-three);
    position: absolute;
    box-shadow: 0px 0px 3px 3px var(--bcg-color-four);
    border-radius: 50%;
    top:62%;
    left:68%;
    width: 15%;
    height: 15%;
}

.ContactUsSign-DotFour{
    background-color: var(--bcg-color-three);
    position: absolute;
    box-shadow: 0px 0px 3px 3px var(--bcg-color-four);
    border-radius: 50%;
    top:45%;
    left:83%;
    width: 10%;
    height: 10%;
}

.ContactUsSign-DotFive{
    background-color: var(--bcg-color-three);
    position: absolute;
    box-shadow: 0px 0px 3px 3px var(--bcg-color-four);
    border-radius: 50%;
    top:45%;
    left:7%;
    width: 10%;
    height: 10%;
}
