.MainPage{
    background-image: linear-gradient(216deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 25%, rgba(42, 42, 42, 0.05) 25%, rgba(42, 42, 42, 0.05) 38%, rgba(223, 223, 223, 0.05) 38%, rgba(223, 223, 223, 0.05) 75%, rgba(36, 36, 36, 0.05) 75%, rgba(36, 36, 36, 0.05) 100%), linear-gradient(44deg, rgba(128, 128, 128, 0.05) 0%, rgba(128, 128, 128, 0.05) 34%, rgba(212, 212, 212, 0.05) 34%, rgba(212, 212, 212, 0.05) 57%, rgba(25, 25, 25, 0.05) 57%, rgba(25, 25, 25, 0.05) 89%, rgba(135, 135, 135, 0.05) 89%, rgba(135, 135, 135, 0.05) 100%), linear-gradient(241deg, rgba(55, 55, 55, 0.05) 0%, rgba(55, 55, 55, 0.05) 14%, rgba(209, 209, 209, 0.05) 14%, rgba(209, 209, 209, 0.05) 60%, rgba(245, 245, 245, 0.05) 60%, rgba(245, 245, 245, 0.05) 69%, rgba(164, 164, 164, 0.05) 69%, rgba(164, 164, 164, 0.05) 100%), linear-gradient(249deg, rgba(248, 248, 248, 0.05) 0%, rgba(248, 248, 248, 0.05) 32%, rgba(148, 148, 148, 0.05) 32%, rgba(148, 148, 148, 0.05) 35%, rgba(202, 202, 202, 0.05) 35%, rgba(202, 202, 202, 0.05) 51%, rgba(181, 181, 181, 0.05) 51%, rgba(181, 181, 181, 0.05) 100%), linear-gradient(92deg, rgb(28, 28, 28), rgb(28, 28, 28));
    width:calc(100vw - 12px);
    display: grid;
    margin-top: 50px;
    margin-bottom:0px;
    color:grey;
}

.MainPage-Title{
    font-size: 2rem;
}

.MainPage-Content{
    background-color: white;
    min-height:calc(100vh - 200px);
    width:var(--page-mockup-width);
    margin: auto;
    margin-top:5vh;
    padding-top:50px;
    padding-bottom:180px;
    width:50vw;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 10px 10px rgba(70,70,70,0.2);
    border-top-left-radius:40px;
    border-top-right-radius:40px;
    transition: all 0.5s linear;
}

@media(max-width:1000px){
    .MainPage-Content{
        border-top-left-radius:30px;
        border-top-right-radius:30px;
        width:90vw;
        min-height:80vh;
    }
}

@media(max-width:1100px){
    .MainPage-Content{
        width:70vw;
        padding-bottom:0px;
        /* margin-top:0vh;
        padding-bottom:30px; */
    }
}

@media(max-width:900px){
    .MainPage-Content{
        /* margin-top:0vh; */
        width:80vw;
        padding-bottom:30px;
    }
}

@media(max-width:600px){
    .MainPage{
        margin-top:0;
    }
    .MainPage-Content{
        /* padding-top:0px; */
        padding-top:50px;
        border-radius:0px;
        margin-top:0vh;
        padding-bottom:0;
        width:100vw;
        min-height:90vh;
    }
}