.ResultItem{
    width:90%;
    margin: auto;
    display: grid;
    padding: 10px;
    grid-template-columns: 100px 1fr;
    align-items: center;
}

.ResultItem-ImageContainer{
    border-radius: 50%;
    height: 70px;
    width: 70px;
    background-color: white;
    box-shadow: 1px 1px 5px 3px rgba(34,34,34,0.2);
    position: relative;
}

.ResultItem-Image{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    background-color: white;
    box-shadow: 1px 1px 3px 5px rgba(34,34,34,0.2);
}

.ResultItem-TextTitle{
    color:var(--bcg-color-four);
    font-size: 100%;
    text-align: left;
    font-weight: 500;
}

.ResultItem-Text{
    color:var(--bcg-color-four);
    font-size: 90%;
    text-align: left;
}