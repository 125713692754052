.AtomSignDynamic{
    width:90%;
    margin: auto;
    z-index: 2;
    margin-top:50px;
    margin-bottom:50px;
}

.AtomSignDynamic-ImageHolder{
    border-radius: 50%;
    height: 100%;
    width: 100%;
    margin: auto;
    background-color: blueviolet;
    z-index: 3;
    position: relative;
}

.AtomSignDynamic-ImageContainer{
    position: absolute;
    border-radius: 50%;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    box-shadow: 0px -1px 5px 3px rgba(34,34,34,0.2);
    background-color: white;
    z-index: 3;
}

.AtomSignDynamic-ImageItem{
    position: absolute;
    border-radius: 50%;
    top:5%;
    left:5%;
    height: 90%;
    width: 90%;
    z-index: 4;
}

.AtomSignDynamic-ImageItem-VisualElement{
    position: absolute;
    border-top-left-radius: 30px 60px;
    border-top-right-radius: 30px 60px;
    border-bottom-left-radius: 30px 60px;
    border-bottom-right-radius: 30px 60px;
    top: 10%;
    left: 30%;
    height: 80%;
    width: 40%;
    box-shadow: 0px 0px 1px 1px rgba(158,158,158,1),0px 0px 1px 1px rgba(178,178,178,1) inset;
    z-index: 4;
    transition: all 0.3s linear;
}

.AtomSignDynamic-ImageItem-VisualElement-CenterPiece{
    position: absolute;
    border-radius: 50%;
    top: 40%;
    left: 40%;
    height: 20%;
    width: 20%;
    background-color: rgb(255,255,255);
    z-index: 4;
    transition: all 0.3s linear;
}


.AtomSignDynamic-ImageItem-VisualElement-SpinOne{
    transform: rotate(120deg);
}

.AtomSignDynamic-ImageItem-VisualElement-SpinTwo{
    transform: rotate(240deg);
}

.AtomSignDynamic-Image{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    z-index: 5;
}
