.NavCategory{
    margin-left: 50px;
    color:var(--bcg-color-two);
    transition: all 0.3s linear;
    padding:5px;  
    cursor: pointer;
}

.NavCategory:hover{
    transform: scale(1.1,1.1);
    color:var(--bcg-color-four);
}

.NavCategory-Selected{
    transform: scale(1.1,1.1);
    color:var(--bcg-color-four);
}

@media(max-width:1200px){
    .NavCategory{
        margin-left: 30px;
        font-size:90%;
    }
}


@media(max-width:900px){
    .NavCategory{
        margin-left: 10px;
        font-size:80%;
    }
}

@media(max-width:600px){
    .NavCategory{
        margin-left: 10px;
        font-size:60%;
        padding: 2px;
        padding-top:10px;
    }
}