:root{
    /* --readmorebutton-color:var(--bcg-color-five); */
    --readmorebutton-color:var(--bcg-color-four);
}

.ReadMoreButton{
    margin-top: 10px;
    font-size: 60%;   
}

.ReadMoreButton-Hidden{
    height: 0px;
    overflow-y: hidden;   
}

.ReadMoreButton-Button{
    display: grid;
    width: 120px;
    grid-template-columns: 30px 1fr;
    color: var(--readmorebutton-color);
    /* box-shadow: 1px 3px 5px 2px rgba(34,34,34,0.1),1px 1px 2px 2px rgba(34,34,34,0.1); */
    padding: 5px;
    border-radius: 50px;
    transition: all 0.3s linear;
}

.ReadMoreButton-ButtonText{
    padding:4px;
}

.ReadMoreButton-Button:hover{
    transform: scale(1.1,1.1);
    cursor: pointer;
}

.ReadMoreButton-Button:active{
    transform: scale(0.9,0.9);
}

.ReadMoreButton-LongBioHolder{
    transition: all 0.2s linear;   
}

.ReadMoreButton-LongBioHolder-Hidden{
    height: 0px;
    overflow-y: hidden;   
}