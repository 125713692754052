.LinkedInIcon{
    height: 100%;
    width:100%;
    position: relative;
    border-radius: 50%;
}

.LinkedInIcon-BeamOne{
    position: absolute;
    top: 28%;
    left: 39%;
    width: 5%;
    height: 20%;
    border-radius: 6px;
    background-color: rgba(34,34,34,0.1);
    box-shadow: 1px 1px 3px 1px rgba(34,34,34,0.5);
    transform: rotate(54deg);
}

.LinkedInIcon-BeamTwo{
    position: absolute;
    top: 55%;
    left: 36%;
    width: 5%;
    height: 20%;
    border-radius: 6px;
    background-color: rgba(34,34,34,0.1);
    box-shadow: 1px 1px 3px 1px rgba(34,34,34,0.5);
    transform: rotate(-54deg);
}

.LinkedInIcon-BaseOne{
    position: absolute;
    top: 11%;
    left: 49%;
    width: 25%;
    height: 25%;
    border-radius: 6px;
    background-color: rgba(34,34,34,0.1);
    box-shadow: 1px 1px 3px 1px rgba(34,34,34,0.5);
    transition: all 0.2s linear;
}

.LinkedInIcon-BaseTwo{
    position: absolute;
    top: 38%;
    left: 7%;
    width: 25%;
    height: 25%;
    border-radius: 6px;
    background-color: rgba(34,34,34,0.1);
    box-shadow: 1px 1px 3px 1px rgba(34,34,34,0.5);
    transition: all 0.2s linear;
}

.LinkedInIcon-BaseThree{
    position: absolute;
    top: 63%;
    left: 49%;
    width: 25%;
    height: 25%;
    border-radius: 6px;
    background-color: rgba(34,34,34,0.1);
    box-shadow: 1px 1px 3px 1px rgba(34,34,34,0.5);
    transition: all 0.2s linear;
}


.LinkedInIcon-BaseOne-Trial{
    left: 38%;
}

.LinkedInIcon-BaseTwo-Trial{
    left: 38%;
}

.LinkedInIcon-BaseThree-Trial{
    left: 38%;
}

.LinkedInIcon-BeamOne-Trial{
    opacity: 0;
}

.LinkedInIcon-BeamTwo-Trial{
    opacity: 0;
}

.LinkedInIcon-Image{
    position: absolute;
    top:10%;
    left:10%;
    width:80%;
    height:80%;
}

.LinkedInIcon-Top{
    position: absolute;
    top: 20%;
    left: 15%;
    width: 70%;
    height: 30%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 40px 20px;
    border-bottom-right-radius: 40px 20px;
    box-shadow: 1px 1px 1px 1px rgba(34,34,34,0.3);
    transition: all 0.3s linear;
}

.LinkedInIcon-Top:hover{
    background-color: blueviolet;
}