.ContactUs{
    padding-top: 50px;
    width:90%;
    margin: auto;
    z-index: 2;
    min-height: 60vh;
    transition: all 0.25s linear;
}

.ContactUs-LogoImageHolder{
    margin: auto;
    margin-top:10px;
    width: 100px;
    height: 100px;
    background-color: var(--bcg-color-four);
    box-shadow: 0px 0px 0px 2px var(--bcg-color-three) inset, 0px 0px 3px 5px rgba(34,34,34,0.2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContactUs-LogoImage{
    width: 80%;
    height: 80%;
    filter: contrast(0%) brightness(2);
}

.ContactUs-ContactsContainer{
    display: flex;
    justify-items: center;
    padding: 10px;
    padding-top: 40px;
}

.ContactUs-ImageHolder{
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin: auto;
    /* background-color: blueviolet; */
    z-index: 3;
    position: relative;
}

.ContactUs-ImageContainer{
    position: absolute;
    border-radius: 50%;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    box-shadow: 0px -1px 5px 3px rgba(34,34,34,0.2);
    background-color: white;
    z-index: 3;
}

.ContactUs-ImageItem{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    background-color: darkblue;
    z-index: 4;
}

.ContactUs-Image{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    z-index: 5;
}

.ContactUs-TextContainer{
    font-size:100%;
    margin-top: 100px;
    padding-top:10px;
    padding-bottom:20px;
    background-image: linear-gradient(216deg, rgba(77, 77, 77, 0.05) 0%, rgba(77, 77, 77, 0.05) 25%, rgba(42, 42, 42, 0.05) 25%, rgba(42, 42, 42, 0.05) 38%, rgba(223, 223, 223, 0.05) 38%, rgba(223, 223, 223, 0.05) 75%, rgba(36, 36, 36, 0.05) 75%, rgba(36, 36, 36, 0.05) 100%), linear-gradient(44deg, rgba(128, 128, 128, 0.05) 0%, rgba(128, 128, 128, 0.05) 34%, rgba(212, 212, 212, 0.05) 34%, rgba(212, 212, 212, 0.05) 57%, rgba(25, 25, 25, 0.05) 57%, rgba(25, 25, 25, 0.05) 89%, rgba(135, 135, 135, 0.05) 89%, rgba(135, 135, 135, 0.05) 100%), linear-gradient(241deg, rgba(55, 55, 55, 0.05) 0%, rgba(55, 55, 55, 0.05) 14%, rgba(209, 209, 209, 0.05) 14%, rgba(209, 209, 209, 0.05) 60%, rgba(245, 245, 245, 0.05) 60%, rgba(245, 245, 245, 0.05) 69%, rgba(164, 164, 164, 0.05) 69%, rgba(164, 164, 164, 0.05) 100%), linear-gradient(249deg, rgba(248, 248, 248, 0.05) 0%, rgba(248, 248, 248, 0.05) 32%, rgba(148, 148, 148, 0.05) 32%, rgba(148, 148, 148, 0.05) 35%, rgba(202, 202, 202, 0.05) 35%, rgba(202, 202, 202, 0.05) 51%, rgba(181, 181, 181, 0.05) 51%, rgba(181, 181, 181, 0.05) 100%), 
    linear-gradient(92deg, rgba(91,91,91,1), rgba(117, 117, 117, 1));
    height:100%;
    border-radius: 20px;
    box-shadow: 0px 10px 7px 3px rgba(34,34,34,0.4);
    transition: all 0.25s linear;
}

.ContactUs-Title{
    font-size:200%;
    font-weight: bold;
    margin-top:20px;
    color:var(--bcg-color-four);
}

.ContactUs-Text{
    font-size:100%;
    color:var(--bcg-color-three);
    text-align: center;
    width: 70%;
    margin: auto;
}
/* 
@media(max-width:1100px){

} */

@media(max-width:900px){
    .ContactUs-TextContainer{
        border-radius: 40px;
    }
}

@media(max-width:600px){
    .ContactUs{
        width:95%;
    }
    .ContactUs-TextContainer{
        border-radius: 0px;
    }
}