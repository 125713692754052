.NavBar-Main{
    height:50px;
    background-color: rgba(255,253,253,0.9);
    width:100vw;
    position: fixed;
    top:0px;
    left:0px;
    z-index: 100;
    display: grid;
}

.NavBar-Hidden{
    opacity:0;
}


.NavBar-Top-Logo{
    position: absolute;
    height:50px;
    width:50px;
    left:2px;
    transition: all 0.3s linear;
    cursor: pointer;
}

.NavBar-Top-Logo:hover{
    transform: scale(1.05,1.05);
}

.NavBar-Top-Logo:active{
    transform: scale(0.9,0.9);
}

.NavBar-Menu-Holder{
    width:50px;
    height:50px;
    justify-self:right;
    margin-right:20px;
}


.NavBar-TopicHolder{
    color:rgb(57, 70, 180);
    position: absolute;
    top:0px;
    /* left:100px; */
    display: flex;
    width: calc(100% - 100px);
    font: 1em "Levenim MT", sans-serif;
    justify-items: center;
    justify-content: center;
}

.NavBar-TopicItem{
    margin-left: 50px;
    color:var(--bcg-color-two);
    transition: all 0.3s linear;
    padding:5px;
}

.NavBar-TopicItem:hover{
    transform: scale(1.1,1.1);
    color:var(--bcg-color-four);
}

@media(max-width:900px){
    .NavBar-TopicItem{
        margin-left: 25px;

    }

    .NavBar-TopicHolder{
        margin-top: 5px;
        left:50px;
    }
}


@media(max-width:600px){
    .NavBar-TopicItem{
        margin-left: 10px;
        /* font-size: 40%; */
    }

    .NavBar-Main{
        height:40px;
    }
}