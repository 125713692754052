.AtomSign{
    width:90%;
    margin: auto;
    z-index: 2;
    margin-top:50px;
    margin-bottom:50px;
}

.AtomSign-ImageHolder{
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin: auto;
    background-color: blueviolet;
    z-index: 3;
    position: relative;
}

.AtomSign-ImageContainer{
    position: absolute;
    border-radius: 50%;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    box-shadow: 0px -1px 5px 3px rgba(34,34,34,0.2);
    background-color: white;
    z-index: 3;
}

.AtomSign-ImageItem{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    background-color: rgba(35,12,51,1);
    z-index: 4;
}

.AtomSign-ImageItem-VisualElement{
    position: absolute;
    border-top-left-radius: 30px 60px;
    border-top-right-radius: 30px 60px;
    border-bottom-left-radius: 30px 60px;
    border-bottom-right-radius: 30px 60px;
    top: 10%;
    left: 30%;
    height: 80%;
    width: 40%;
    box-shadow: 0px 0px 1px 3px rgba(255,255,255,1),0px 0px 1px 3px rgba(255,255,255,1) inset;
    z-index: 4;
    transition: all 0.3s linear;
}

.AtomSign-ImageItem-VisualElement-CenterPiece{
    position: absolute;
    border-radius: 50%;
    top: 40%;
    left: 40%;
    height: 20%;
    width: 20%;
    background-color: rgb(255,255,255);
    z-index: 4;
    transition: all 0.3s linear;
}

.AtomSign-ImageItem-VisualElement-SpinOne{
    transform: rotate(120deg);
}

.AtomSign-ImageItem-VisualElement-SpinTwo{
    transform: rotate(240deg);
}

.AtomSign-Image{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    z-index: 5;
}

.AtomSign-ScaleupAnimation{
    animation:1s linear 1 both running scaleUp;
}

@keyframes scaleUp {
    0% {
      transform: rotate(120deg);
    }
    100% {
        transform: rotate(0deg) scale(1.15);
        box-shadow: 0px 0px 20px 11px rgba(200,200,200,0.3) inset;
    }  
  }

.AtomSign-AnimationTwo{
    animation:1s linear 1 both running slideinTwo;
}

@keyframes slideinTwo {
    0% {
      transform: rotate(240deg);
    }
    50%{
      transform: rotate(60deg) scale(1.15);    
    }
    100% {
      transform: rotate(240deg);
    }
  }
