.TeamMember{
    width:calc(100% - 4px);
    padding: 10px;
    background-color: var(--bcg-color-three);
    box-shadow: 0px 10px 15px 7px rgba(34,34,34,0.2), 0px 11px 18px 12px rgba(34,34,34,0.2);
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-template-areas:
    "image intro"
    "details details"
    "readmore readmore"
    "more more";
    margin: auto;
    margin:5px;
    min-height: 200px;
}

.TeamMember-Hidden{
    display: none;
}

.TeamMember-Name{
    padding-top:20px;
    font-size: 120%;
    font-weight: bold;
    /* color: var(--bcg-color-one); */
    color: var(--bcg-color-four);
}

.TeamMember-Title{
    font-size: 120%;
    font-weight: bold;
    /* color: var(--bcg-color-four); */
    color: var(--bcg-color-two);
    text-align: center;    
}

.TeamMember-Text{
    text-align: left;
    padding-top:20px;
    font-size: 100%;
    color: var(--bcg-color-four);
}

.TeamMember-Details{
    margin-top: 5px;
    padding-left:20px;
    text-align: left;
    grid-area: details;
}

.TeamMember-More{
    margin-top: 5px;
    padding-left:20px;
    text-align: left;
    grid-area: more;
}

.TeamMember-ReadMore{
    padding-left:20px;
    text-align: left;
    grid-area: readmore;
}

.TeamMember-Details-Hidden{
    overflow: hidden;
    height: 0;
}

.TeamMember-More-Hidden{
    overflow: hidden;
    height: 0;
}

.TeamMember-ImageHolder{
    background-color: var(--bcg-color-three);
    box-shadow: 0px 3px 0px 3px rgba(34,34,34,0.2), -20px 0px 20px 0px rgba(34,34,34,0.2);
    border-radius: 50%;
    height: 150px;
    width: 150px;
    transition: all 0.3s linear;
}

.TeamMember-ImageHolder-Image{
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

@media(max-width:900px){
    .TeamMember-ImageHolder{
        box-shadow: 0px 3px 4px 3px rgba(34,34,34,0.2), -20px 0px 20px 0px rgba(34,34,34,0.2);
        height: 150px;
        width: 150px;
    }
}