:root{
    /* --aboutussign-bgc-color:rgba(35,12,51,1); */
    --plusminussign-bgc-color:var(--bcg-color-four);
}

.PlusMinusSign{
    width:30px;
    height:30px;
    position: relative;

}

.PlusMinusSign-BeamOne{
    position: absolute;
    top:47%;
    left:30%;
    width:40%;
    height:6%;
    border-radius: 30px;
    background-color: var(--plusminussign-bgc-color);
    /* box-shadow: 1px 1px 3px 5px rgba(34,34,34,0.2); */
    transition: all 0.3s linear;
}

.PlusMinusSign-BeamTwo{
    position: absolute;
    top:47%;
    left:30%;
    width:40%;
    height:6%;
    border-radius: 30px;
    background-color: var(--plusminussign-bgc-color);
    /* box-shadow: 1px 1px 3px 5px rgba(34,34,34,0.2); */
    transition: all 0.3s linear;
    transform: rotate(90deg);
}

.PlusMinusSign-BeamTwo-Hidden{
    transform: rotate(0deg);
}