:root{
      /* 132,108,91
    127,126,255
    255,253,253
    35,12,51
    41,77,74 */

  --main-theme-color:white;


  --bcg-color-one:rgba(132,108,91,1);
  --bcg-color-two:rgba(127,126,255,1);
  --bcg-color-three:rgba(255,253,253,1);
  --bcg-color-four:rgba(35,12,51,1);
  /* --bcg-color-five:rgba(41,77,74,1); */
  --bcg-color-five:rgba(0,105,137,1);

  --main-login-bcg-color:rgba(0,0,130,0.7);
  --main-register-bar-color:rgba(170,30,40,1);
  --main-register-bcg-color:rgba(58,58,98,0.8);

  --page-mockup-height:90vh;
  --page-mockup-width:calc(90vh * 9 / 16);

  --control-wheel-icon-color:rgba(78,78,78,1);

  --button-main-color-active:rgba(170,30,40,1);

  --form-title-color:rgb(140, 3, 3);

  --brand-color-generic:rgba(170,40,50,1);

  --scrollbar-color-generic:rgb(45, 16, 66);
  --scrollbar-color-active:rgba(35,12,51,1);
}

.App {
  text-align: center;
  transition: all 1s linear;
  /* overflow-y:hidden; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* width: calc(100% - 20px); */
  width: 100%;
  /* overflow-y: hidden; */
}

.App-link {
  color: rgba(35,12,51,1);
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  /* border-radius: 10px; */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:  var(--scrollbar-color-generic); 
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-color-active); 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(max-width:600px){
  .App-header{
    width: calc(100% - 20px);
  }
}