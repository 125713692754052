.AboutUs{
    width:90%;
    margin: auto;
    z-index: 2;
    min-height: 60vh;
    transition: all 0.25s linear;
}

.AboutUs-ImageHolder{
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin: auto;
    z-index: 3;
    position: relative;
}

.AboutUs-ImageContainer{
    position: absolute;
    border-radius: 50%;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    box-shadow: 0px -1px 5px 3px rgba(34,34,34,0.2);
    background-color: white;
    z-index: 3;
}

.AboutUs-ImageItem{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    background-color: rgba(35,12,51,1);
    z-index: 4;
}

.AboutUs-Image{
    position: absolute;
    border-radius: 50%;
    top:10%;
    left:10%;
    height: 80%;
    width: 80%;
    z-index: 5;
}

.AboutUs-TextContainer{
    font-size:100%;
    margin-top: -100px;
    background: linear-gradient(rgba(35,12,51,1), rgb(49, 17, 71));
    height:100%;
    border-radius: 20px;
    box-shadow: 0px 10px 7px 3px rgba(34,34,34,0.4);
    padding-bottom: 50px;
    transition: all 0.25s linear;
}

.AboutUs-Title{
    margin-top: 90px;
    font-size:200%;
    font-weight: bold;
    color:white;
}

.AboutUs-Text{
    font-size:100%;
    color:white;
    text-align: left;
    /* width: 70%; */
    width: 85%;
    margin: auto;
}

@media(max-width:900px){
    .AboutUs-TextContainer{
        border-radius:40px;
    }
}

@media(max-width:600px){
    .AboutUs{
        width:95%;
    }
    .AboutUs-TextContainer{
        border-radius:0;
    }
}